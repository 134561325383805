export function setCookie(
  name: string,
  value: string,
  expires: Date | number,
  domain?: string
) {
  const cookie = [
    `${name}=${value};`,
    expires ? `expires=${new Date(expires).toUTCString()};` : null,
    domain ? `domain=${domain};` : null,
    'path=/',
  ].filter(Boolean);

  document.cookie = cookie.join(' ');
}
